import * as React from "react";
import { Link } from "gatsby";
import { FiChevronsRight, FiInfo } from "react-icons/fi";
import { GiGardeningShears } from "react-icons/gi";
import "twin.macro";

export default function NeedHlep() {
  return (
    <section id="needhelp" tw="text-secondary">
      <div id="needhelp" tw="container mx-auto py-24 px-6">
        <h2 tw="text-4xl lg:text-5xl font-medium text-center leading-tight">
          Sie planen{" "}
          <span tw="text-primary">Erneuerungen an Ihrem Garten </span>
          <br />
          oder wollen Unterstzützung bei der{" "}
          <span tw="text-primary">Gartenpflege?</span>
        </h2>
        <ul tw="mt-24 max-w-2xl mx-auto text-xl space-y-8">
          <li tw="flex">
            <GiGardeningShears tw="text-primary mr-4 flex-shrink-0" size={36} />{" "}
            Hierfür sind wir genau der richtige Ansprechpartner. Tun Sie sich
            und ihrem Garten etwas Gutes, mit einem verlässlichen Profi an Ihrer
            Seite!
          </li>
          <li tw="flex">
            <GiGardeningShears tw="text-primary mr-4 flex-shrink-0" size={36} />{" "}
            Wir bieten Neubepflanzung, Gartenservice und Pflasterarbeiten
          </li>
          <li tw="flex">
            <GiGardeningShears tw="text-primary mr-4 flex-shrink-0" size={36} />
            Kontaktieren Sie uns gerne mit ihrem Anliegen
          </li>
          <li tw="flex">
            <GiGardeningShears tw="text-primary mr-4 flex-shrink-0" size={36} />
            Wir schätzen bei Besichtigung den Arbeitsaufwand Ihres Auftrags ein.
            Auf dieser Grundlage erstellen wir Ihr individuelles Angebot
          </li>
          <li tw="flex">
            <GiGardeningShears tw="text-primary mr-4 flex-shrink-0" size={36} />
            Natürlich haben Sie zusätzlich die Möglichkeit, Fragen zu stellen
            und Einzelheiten zu besprechen.
          </li>
        </ul>
        <h4 tw="text-2xl md:text-4xl text-primary mx-auto max-w-3xl mt-12 text-center">
          <FiInfo size={48} tw="inline-block mb-6" />
          <br />
          Sollten Sie für einen Besichtigungstermin keine Zeit haben, können Sie
          uns alternativ folgende Angaben machen:
        </h4>
        <ul tw="max-w-sm mx-auto mt-12 text-xl space-y-8">
          <li tw="flex items-center">
            <FiChevronsRight tw="text-secondary mr-4" size={48} />
            <Link to="/#hero">Ihr Anliegen im Kontakt per WhatsApp</Link>
          </li>

          <li tw="flex items-center">
            <FiChevronsRight tw="text-secondary mr-4" size={48} />
            Beschreibung des Auftrags
          </li>
          <li tw="flex items-center">
            <FiChevronsRight tw="text-secondary mr-4" size={48} />
            Bilder von ihrem Gartenprojekt
          </li>
          <li tw="flex items-center">
            <FiChevronsRight tw="text-secondary mr-4" size={48} />
            Die geplante Dauer des Auftrags
          </li>
        </ul>
      </div>
    </section>
  );
}
