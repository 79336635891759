import * as React from "react";
import { FiCheckCircle } from "react-icons/fi";
import "twin.macro";

export default function Services() {
  return (
    <section id="service" tw="bg-gray-100">
      <div tw="container mx-auto py-24 px-6">
        <h2 tw="text-4xl lg:text-5xl font-medium text-center">
          Unsere <span tw="text-primary">Leistungen</span>
        </h2>
        <div tw="flex flex-wrap items-stretch justify-around mt-12">
          <div tw="w-full h-full lg:w-1/2 2xl:w-1/4 flex justify-center">
            <div tw="w-2/3 bg-white py-12 px-8 flex items-center font-medium border-l-4 border-primary mb-6 shadow-2xl">
              <span tw="inline-block mr-4 text-primary p-4 rounded-full bg-primary bg-opacity-20">
                <FiCheckCircle size="24" />
              </span>
              <p>Heckenpflanzung- und Schnitt</p>
            </div>
          </div>
          <div tw="w-full h-full lg:w-1/2 2xl:w-1/4 flex justify-center">
            <div tw="w-2/3 bg-white py-12 px-8 flex items-center font-medium border-l-4 border-primary mb-6 shadow-2xl">
              <span tw="inline-block mr-4 text-primary p-4 rounded-full bg-primary bg-opacity-20">
                <FiCheckCircle size="24" />
              </span>
              <p>Gehölzschnitt</p>
            </div>
          </div>
          <div tw="w-full h-full lg:w-1/2 2xl:w-1/4 flex justify-center">
            <div tw="w-2/3 bg-white py-12 px-8 flex items-center font-medium border-l-4 border-primary mb-6 shadow-2xl">
              <span tw="inline-block mr-4 text-primary p-4 rounded-full bg-primary bg-opacity-20">
                <FiCheckCircle size="24" />
              </span>
              <p>Rasenmähen</p>
            </div>
          </div>
          <div tw="w-full h-full lg:w-1/2 2xl:w-1/4 flex justify-center">
            <div tw="w-2/3 bg-white py-12 px-8 flex items-center font-medium border-l-4 border-primary mb-6 shadow-2xl">
              <span tw="inline-block mr-4 text-primary p-4 rounded-full bg-primary bg-opacity-20">
                <FiCheckCircle size="24" />
              </span>
              <p>Vertikutieren</p>
            </div>
          </div>
          <div tw="w-full h-full lg:w-1/2 2xl:w-1/4 flex justify-center">
            <div tw="w-2/3 bg-white py-12 px-8 flex items-center font-medium border-l-4 border-primary mb-6 shadow-2xl">
              <span tw="inline-block mr-4 text-primary p-4 rounded-full bg-primary bg-opacity-20">
                <FiCheckCircle size="24" />
              </span>
              <p>Bepflanzungen</p>
            </div>
          </div>
          <div tw="w-full h-full lg:w-1/2 2xl:w-1/4 flex justify-center">
            <div tw="w-2/3 bg-white py-12 px-8 flex items-center font-medium border-l-4 border-primary mb-6 shadow-2xl">
              <span tw="inline-block mr-4 text-primary p-4 rounded-full bg-primary bg-opacity-20">
                <FiCheckCircle size="24" />
              </span>
              <p>Bäume schneiden</p>
            </div>
          </div>
          <div tw="w-full h-full lg:w-1/2 2xl:w-1/4 flex justify-center">
            <div tw="w-2/3 bg-white py-12 px-8 flex items-center font-medium border-l-4 border-primary mb-6 shadow-2xl">
              <span tw="inline-block mr-4 text-primary p-4 rounded-full bg-primary bg-opacity-20">
                <FiCheckCircle size="24" />
              </span>
              <p>Pflasterarbeiten</p>
            </div>
          </div>
          <div tw="w-full h-full lg:w-1/2 2xl:w-1/4 flex justify-center">
            <div tw="w-2/3 bg-white py-12 px-8 flex items-center font-medium border-l-4 border-primary mb-6 shadow-2xl">
              <span tw="inline-block mr-4 text-primary p-4 rounded-full bg-primary bg-opacity-20">
                <FiCheckCircle size="24" />
              </span>
              <p>Gartenpflege</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
