import * as React from "react";
import "twin.macro";

export default function Descriptive() {
  return (
    <section id="descriptive" tw="container mx-auto py-24 px-6">
      <h2 tw="text-4xl lg:text-5xl font-medium text-center leading-tight">
        A & F Galabau – Ihr lokaler Garten- und Landschaftsbau Partner für
        <br /> <span tw="text-primary">Flensburg und Umgebung</span>
      </h2>
      <p tw="text-2xl leading-relaxed border-t-4 max-w-4xl mx-auto mt-16 border-primary p-12 shadow-xl">
        Wir, das Team von A & F Garten- und Landschaftsbau sind Ihr
        professioneller Partner für Ihre Anliegen rund um Gartengestaltung und
        Pflege. Wir übernehmen Ihre Arbeiten und garantieren Zuverlässigkeit und
        Zufriedenheit. Der persönliche Kontakt und das direkte Gespräch mit
        Ihnen gehen uns über alles: Rufen Sie uns an oder schicken Sie
        unkompliziert eine Nachricht auf Whatsapp oder im folgenden
        Kontaktformular. Wir sind gerne für Sie da und freuen uns schon auf das
        Gespräch mit Ihnen!
      </p>
    </section>
  );
}
