import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { FiPhone } from "react-icons/fi";
import { ImWhatsapp } from "react-icons/im";
import "twin.macro";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

export default function Hero() {
  const { site } = useStaticQuery(query);
  const { tel } = site.siteMetadata;

  // TODO: https://stackoverflow.com/a/70605541

  return (
    <section id="hero" tw="container mx-auto px-6 py-12 items-center lg:flex">
      <div tw="lg:w-1/2 text-center lg:text-left">
        <h1 tw="text-4xl md:text-5xl xl:text-6xl font-medium leading-snug md:leading-tight xl:leading-tight">
          <span tw="text-primary">Garten- und Landschaftsbau</span> <br />
          in Flensburg und Umgebung
        </h1>
        <a
          tw="mx-2 text-lg hover:bg-opacity-90 py-3 px-6 mt-8 rounded-md inline-flex items-center font-medium bg-primary text-white"
          href={"tel:" + tel}
        >
          <FiPhone size={24} tw="mr-2" /> {tel}
        </a>
        {/* https://faq.whatsapp.com/android/im-an-android-developer-how-can-i-integrate-whatsapp-with-my-app
        https://fvdm.com/code/note-whatsapp-url-scheme */}
        <a
          tw="mx-2 text-lg hover:bg-opacity-90 py-3 px-6 mt-8 rounded-md inline-flex items-center font-medium bg-primary text-white"
          href={"whatsapp://send?phone=" + tel.replace(/([^0-9])+/g, "")}
        >
          <ImWhatsapp size={24} tw="mr-2" /> {tel}
        </a>
      </div>
      <div tw="lg:w-1/2 py-12 lg:px-12">
        {/* https://swiperjs.com/demos */}
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true, dynamicBullets: true }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/team.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Team von A & F Garten-und Landschaftsbau"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/first.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Garten und Schubkarre"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/second.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Arbeit von A & F Garten-und Landschaftsbau"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/third.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Blumenpflanzen"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/fourth.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Hecke schneiden"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/fifth.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Hecke geschnitten"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/sixth.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Rasenmähen"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/seventh.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Pflasterarbeiten"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/8.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Pflasterarbeiten"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/9.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Bäume schneiden"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/10.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Erfahrung und Kompetenz in Fragen rund um Garten und Landschaftsbau"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              tw="rounded-2xl"
              src="../images/11.jpg"
              layout="constrained"
              aspectRatio={4 / 3}
              placeholder="blurred"
              alt="Gartenarbeiten"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        tel
      }
    }
  }
`;
