import * as React from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Services from "../components/services";
import WhyUs from "../components/whyus";
import NeedHlep from "../components/needhelp";
import Descriptive from "../components/descriptive";
import Contact from "../components/contact";

export default function IndexPage() {
  return (
    <Layout>
      <SEO
        title="A & F"
        description="Garten- und Landschaftsbau mit Gartenpflege in Flensburg und Umgebung"
      />
      <Hero />
      <Services />
      <WhyUs />
      <NeedHlep />
      <Descriptive />
      <Contact />
    </Layout>
  );
}
